import React from "react";
import Vacancy from "./Vacancies/Vacancy";
import Joinus from "./Joinus/Joinus";
import Perks from "./Perks/Perks";


function Internship() {
  return (
    <div>
      <Joinus />
      <Vacancy />
       <Perks/>
    </div>
  );

}

export default Internship;
