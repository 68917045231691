const Section5Data = [
  {
    id: "1",
    img: "https://drive.google.com/uc?id=1C5qFCEzop_NVmNfW2IgIvChpNKzCSpLj",
  },
  {
    id: "2",
    img: "https://drive.google.com/uc?id=1CA_l66hFLXC5u5mEW6sNUVAftojdMl_t",
  },
  {
    id: "3",
    img: "https://drive.google.com/uc?id=1CH3AWqyhL59a7z180zg3gfiKdGRoNsXN",
  },
  {
    id: "4",
    img: "https://drive.google.com/uc?id=1CHZvqwClwKUPzS60pixsv9xpZIAk3FkQ",
  },
  {
    id: "5",
    img: "https://drive.google.com/uc?id=1Blw1oTGNc_AsNylW3vrxFJLZArM9RW8H",
  },
  {
    id: "6",
    img: "https://drive.google.com/uc?id=1BwKiAXWBIvgigRbdOMh4JftBr_3nDtkr",
  },
];

export default Section5Data;
