export const SliderData = {
  image1: "https://drive.google.com/uc?id=1LmpgOmv3bPwoRj1q39Tf5yg2_P0Wzz-X",
  image2: "https://drive.google.com/uc?id=1TJ-WBYCG28ocV32NF_xUjvPjIlOUjj3C",
  image3: "https://drive.google.com/uc?id=1eB1hO6XzNRIDkbdJe6GtGbfMeLB0owgk",
  image4: "https://drive.google.com/uc?id=1broS6UnkobcMjewuuB5QGp2gB6O90Cga",
  image5: "https://drive.google.com/uc?id=1TwEq-ZryPrrPn-rEqxIB0G__IrGhyMWe",
  image6: "https://drive.google.com/uc?id=14GLg39kHbRuki70RMDx4O_IJC5xqM4cr",
  image7: "https://drive.google.com/uc?id=1XTDopW7H6kkKfSUeX-LxWUN1OsLPDcZo",
}

export default SliderData;